var PeopleMedia = PeopleMedia || {};
PeopleMedia.CP = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.CP";
    var ClientMessages = {
        "RegHandle": "Please enter your first name.",
        "RegEmail": "Please enter an email address.",
        "RegPassword": "Please enter a password.",
        "RegCountryCode": "Please select a country.",
        "RegPostalcode": "Please enter a valid zip/postal code.",
        "RegCity": "Please enter a city.",
        "RegState": "Please enter a state.",
        "RegGender": "Please enter your gender.",
        "RegBirthDay": "Please enter the Day of your birth.",
        "RegBirthMonth": "Please enter the Month of your birth.",
        "RegBirthYear": "Please enter the Year of your birth.",
        "RegBirthGeneric": "Please enter your complete birthday.",
        "RegBirthUnder18": "You must be past your 18th birthday to participate in this site.",
        "RegTermsAndConditions": "You must agree to the Terms and Conditions.",
        "LoginHandle": "Please enter your email address.",
        "LoginPassword": "Please enter your password.",
        "RegCaptcha": "Please prove you're not a robot"
    };
    var _sessionid = '00000000-0000-0000-0000-000000000000';
    var _theme = 0;
    var _domainName;
    var _subdomain;
    var _siteID;
    var _domainID;
    var _serverID;
    var _searchID;
    var _searchCategoryID;
    var _pinnedSearchID;
    var _pinnedSearchCategoryID;
    var _affiliateID;
    var _pageFolder;
    var _pagePath;
    var _pageVersion;
    var _pageAlias;
    var _sponsorID;
    var _visitorID;
    var _sessionVisitorID;
    var _username;
    var _genderID;
    var _genderSeekID;
    var _cpServer;
    var _publicMemberId;

    var _ready = false;
    var _initComplete = false;
    var _stacktraceScriptLoaded = false;
    var _autologinEnabled = false;
    var _persistentLoginEnabled = false;
    var _genderSeekEnabled = false;
    var _invalidEmailDomainCheckEnabled = false;
    var _contentVersion = "";
    var _versionedContentRoot = "";
    var _defaultGenderID;
    var _defaultGenderSeekID;
    var _defaultMinAge;
    var _defaultMaxAge;
    var _emailGuid;
    var _eventTrackingEnabled = false;
    var _eventTrackingPermitted = false;
    var _ajaxErrorMessage = "Whoops... something's amiss. Please try your request again. If you see this error repeatedly, please refresh the page.";
    var _facebookAppId = null;
	var _facebookAuthData = null;
    var _onReadyComplete = false;
    var _onReadyHandlers = [];

    var SessionId = function(sessionid) {
        if (arguments.length > 0) {
            _sessionid = sessionid;
        } else {
            return _sessionid;
        }
    };

    var Theme = function(theme) {
        if (arguments.length > 0) {
            _theme = theme;
        } else {
            return _theme;
        }
    };

    var DomainName = function(domainName) {
        if (arguments.length > 0) {
            _domainName = domainName;
        } else {
            if (typeof (_domainName) === 'undefined' || _domainName === null || _domainName.length === 0) {
                return PeopleMedia.Net.getDomainName();
            } else {
                return _domainName;
            }
        }
    };

    var Subdomain = function(subdomain) {
        if (arguments.length > 0) {
            _subdomain = subdomain;
        } else {
            if (typeof (_subdomain) === 'undefined' || _subdomain === null || _subdomain.length === 0) {
                return "www";
            } else {
                return _subdomain;
            }
        }
    };

    var SiteID = function(siteID) {
        if (arguments.length > 0) {
            _siteID = siteID;
        } else {
            return _siteID;
        }
    };

    var DomainID = function(domainID) {
        if (arguments.length > 0) {
            _domainID = domainID;
        } else {
            return _domainID;
        }
    };

    var ServerID = function(serverID) {
        if (arguments.length > 0) {
            _serverID = serverID;
        } else {
            return _serverID;
        }
    };

    var SearchID = function(searchID) {
        if (arguments.length > 0) {
            _searchID = searchID;
        } else {
            return _searchID;
        }
    };

    var SearchCategoryID = function(searchCategoryID) {
        if (arguments.length > 0) {
            _searchCategoryID = searchCategoryID;
        } else {
            return _searchCategoryID;
        }
    };

    var PinnedSearchID = function(pinnedSearchID) {
        if (arguments.length > 0) {
            _pinnedSearchID = pinnedSearchID;
        } else {
            return _pinnedSearchID;
        }
    };

    var PinnedSearchCategoryID = function(pinnedSearchCategoryID) {
        if (arguments.length > 0) {
            _pinnedSearchCategoryID = pinnedSearchCategoryID;
        } else {
            return _pinnedSearchCategoryID;
        }
    };

    var AffiliateID = function(affiliateID) {
        if (arguments.length > 0) {
            _affiliateID = affiliateID;
        } else {
            return _affiliateID;
        }
    };

    var SponsorID = function(sponsorID) {
        if (arguments.length > 0) {
            _sponsorID = sponsorID;
        } else {
            return _sponsorID;
        }
    };

    var VisitorID = function(visitorID) {
        if (arguments.length > 0) {
            _visitorID = visitorID;
        } else {
            return _visitorID;
        }
    };

    var SessionVisitorID = function (visitorID) {
        if (arguments.length > 0) {
            _sessionVisitorID = visitorID;
        } else {
            return _sessionVisitorID;
        }
    };

    var GenderID = function(genderID) {
        if (arguments.length > 0) {
            _genderID = genderID;
        } else {
            return _genderID;
        }
    };

    var GenderSeekID = function(genderSeekID) {
        if (arguments.length > 0) {
            _genderSeekID = genderSeekID;
        } else {
            return _genderSeekID;
        }
    };

    var Username = function (username) {
        if (arguments.length > 0) {
            _username = username;
        } else {
            return _username;
        }
    };

    var CpServer = function(cpServer) {
        if (arguments.length > 0) {
            _cpServer = cpServer;
        } else {
            return _cpServer;
        }
    };

    var PageFolder = function(pageFolder) {
        if (arguments.length > 0) {
            _pageFolder = pageFolder;
        } else {
            return _pageFolder;
        }
    };

    var PagePath = function(pagePath) {
        if (arguments.length > 0) {
            _pagePath = pagePath;
        } else {
            return _pagePath;
        }
    };

    var PageVersion = function(pageVersion) {
        if (arguments.length > 0) {
            _pageVersion = pageVersion;
        } else {
            return _pageVersion;
        }
    };

    var PageAlias = function (pageAlias) {
        if (arguments.length > 0) {
            _pageAlias = pageAlias;
        } else {
            return _pageAlias;
        }
    };

    var AutoLoginEnabled = function(autologinEnabled) {
        if (arguments.length > 0) {
            _autologinEnabled = autologinEnabled;
        } else {
            return _autologinEnabled;
        }
    };

    var PersistentLoginEnabled = function(persistentLoginEnabled) {
        if (arguments.length > 0) {
            _persistentLoginEnabled = persistentLoginEnabled;
        } else {
            return _persistentLoginEnabled;
        }
    };

    var GenderSeekEnabled = function(genderSeekEnabled) {
        if (arguments.length > 0) {
            _genderSeekEnabled = genderSeekEnabled;
        } else {
            return _genderSeekEnabled;
        }
    };

    var InvalidEmailDomainCheckEnabled = function(invalidEmailDomainCheckEnabled) {
        if (arguments.length > 0) {
            _invalidEmailDomainCheckEnabled = invalidEmailDomainCheckEnabled;
        } else {
            return _invalidEmailDomainCheckEnabled;
        }
    };

    var ContentVersion = function(contentVersion) {
        if (arguments.length > 0) {
            _contentVersion = contentVersion;
        } else {
            return _contentVersion;
        }
    };

    var VersionedContentRoot = function(versionedContentRoot) {
        if (arguments.length > 0) {
            _versionedContentRoot = versionedContentRoot;
        } else {
            return _versionedContentRoot;
        }
    };

    var SessionCookieName = function() {
        return "SSKY";
    };

    var FacebookImportCookieName = function() {
        return "Lps.State.Facebook";
    };

    var FacebookAuthCookieName = function() {
        return "FBAD";
    };

    var DefaultGenderID = function(defaultGenderID) {
        if (arguments.length > 0) {
            _defaultGenderID = convertGenderToID(defaultGenderID);
        } else {
            return _defaultGenderID;
        }
    };

    var DefaultGenderSeekID = function(defaultGenderSeekID) {
        if (arguments.length > 0) {
            _defaultGenderSeekID = convertGenderToID(defaultGenderSeekID);
        } else {
            return _defaultGenderSeekID;
        }
    };

    var DefaultMinAge = function (defaultMinAge) {
        var age = null;
        if (arguments.length > 0) {
            if (typeof (defaultMinAge) !== 'undefined' && defaultMinAge !== null) {
                age = parseInt(defaultMinAge, 10);
                if (isNaN(age)) {
                    age = null;
                }
            }
            _defaultMinAge = age;
        } else {
            return _defaultMinAge;
        }
    };

    var DefaultMaxAge = function (defaultMaxAge) {
        var age = null;
        if (arguments.length > 0) {
            if (typeof (defaultMaxAge) !== 'undefined' && defaultMaxAge !== null) {
                age = parseInt(defaultMaxAge, 10);
                if (isNaN(age)) {
                    age = null;
                }
            }
            _defaultMaxAge = age;
        } else {
            return _defaultMaxAge;
        }
    };

    var EmailGuid = function(emailGuid) {
        if (arguments.length > 0) {
            _emailGuid = emailGuid;
        } else {
            return _emailGuid;
        }
    };

    var EventTrackingPermitted = function(eventTrackingPermitted) {
        if (arguments.length > 0) {
            _eventTrackingPermitted = eventTrackingPermitted;
        } else {
            return _eventTrackingPermitted;
        }
    };

    var EventTrackingEnabled = function(eventTrackingEnabled) {
        if (arguments.length > 0) {
            _eventTrackingEnabled = eventTrackingEnabled;
        } else {
            return _eventTrackingEnabled;
        }
    };

    var AjaxErrorMessage = function() {
        return _ajaxErrorMessage;
    };

    var FacebookAppId = function(facebookAppId) {
        if (arguments.length > 0) {
            _facebookAppId = facebookAppId;
        } else {
            return _facebookAppId;
        }
    };

    var FacebookImportReset = function() {
        clearFacebookImportStatus();
    };

    var FacebookImportSelected = function(facebookImportSelected) {
        var status = getFacebookImportStatus();
        if (arguments.length > 0) {
            status = $jq.extend({}, status, { "selected": facebookImportSelected });
            setFacebookImportStatus(status);
        } else {
            return (status && status.selected) ? status.selected : false;
        }
    };

    var FacebookImportAttempted = function(facebookImportAttempted) {
        var status = getFacebookImportStatus();
        if (arguments.length > 0) {
            status = $jq.extend({}, status, { "attempted": facebookImportAttempted });
            setFacebookImportStatus(status);
        } else {
            return (status && status.attempted) ? status.attempted : false;
        }
    };

    var FacebookImportFailed = function(facebookImportFailed) {
        var status = getFacebookImportStatus();
        if (arguments.length > 0) {
            status = $jq.extend({}, status, { "failed": facebookImportFailed });
            setFacebookImportStatus(status);
        } else {
            return (status && status.failed) ? status.failed : false;
        }
    };

    var FacebookImportGreetingFailed = function(facebookImportGreetingFailed) {
        var status = getFacebookImportStatus();
        if (arguments.length > 0) {
            status = $jq.extend({}, status, { "greetingFailed": facebookImportGreetingFailed });
            setFacebookImportStatus(status);
        } else {
            return (status && status.greetingFailed) ? status.greetingFailed : false;
        }
    };

    var FacebookImportPhotoFailed = function(facebookImportPhotoFailed) {
        var status = getFacebookImportStatus();
        if (arguments.length > 0) {
            status = $jq.extend({}, status, { "photoFailed": facebookImportPhotoFailed });
            setFacebookImportStatus(status);
        } else {
            return (status && status.photoFailed) ? status.photoFailed : false;
        }
    };

    var FacebookImportMember = function(facebookMember) {
        var status = getFacebookImportStatus();
        if (arguments.length > 0) {
            status = $jq.extend({}, status, { "member": facebookMember });
            setFacebookImportStatus(status);
        } else {
            return (status && status.member) ? status.member : null;
        }
    };

    var FacebookAuthData = function(facebookAuthData) {
        if (arguments.length > 0) {
            _facebookAuthData = facebookAuthData;
            setFacebookAuthData(_facebookAuthData);
        } else {
            _facebookAuthData = getFacebookAuthData();
            return _facebookAuthData;
        }
    };

    var LpsPostbackHeader = function () {
        var header = {};
        try {
            var tokenName = "__RequestVerificationToken";
            var $af = $jq("#lpsrvt");
            if ($af.length > 0) {
                var $token = $af.find("input[name='" + tokenName + "']");
                if ($token.length > 0) {
                    header[tokenName] = $token.val();
                }
            }
        } catch (ex) {
        }
        return header;
    };

    var isAjaxCancelled = function(jqXHR, textStatus, errorThrown) {
        try {
            // return true when user navigates away from the page by refreshing, clicking a link, or changing the URL in the browser
            return (errorThrown !== 'timeout' && jqXHR && (jqXHR.readyState === 0 || jqXHR.status === 0));
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "isAjaxCancelled" });
            return false;
        }
    };

    var convertAjaxErrorToFault = function(jqXHR, textStatus, errorThrown) {
        var codeValue = "Ajax";
        var isCancelled = false;
        var reason = "Please try your request again.";
        try {
            codeValue = "Ajax" + ((typeof (errorThrown) !== 'undefined' && errorThrown !== null) ? (" " + errorThrown) : "");
            isCancelled = isAjaxCancelled(jqXHR, textStatus, errorThrown);
            var responseText = (!isCancelled && jqXHR && jqXHR.responseText) ? jqXHR.responseText : null;
            if (responseText !== null && responseText.length > 0 && responseText.indexOf("<") === -1 && responseText.indexOf("{") === -1) {
                reason = jqXHR.responseText;
            } else {
                reason = AjaxErrorMessage();
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "convertAjaxErrorToFault" });
        }
        return {
            "Code": codeValue,
            "Subcode": textStatus,
            "IsValid": false,
            "Fault": { "Code": { "Subcode": { "Value": { "Name": textStatus } }, "Value": { "Name": codeValue } }, "Reason": reason, "IsAjaxError": true, "IsCancelled": isCancelled },
            "IsCancelled": isCancelled,
            "jqXHR": jqXHR
        };
    };

    var convertFaultToError = function(obj, field) {
        var errorObj = {};
        try {
            errorObj.IsAjaxError = (typeof (obj.IsAjaxError) !== 'undefined' && obj.IsAjaxError === true);
            errorObj.IsCancelled = (typeof (obj.IsCancelled) !== 'undefined' && obj.IsCancelled === true);
            if (obj) {
                if (obj.Code) {
                    errorObj.Code = (obj.Code.Value && obj.Code.Value.Name) ? obj.Code.Value.Name : obj.Code;
                    if (obj.Code.Subcode && obj.Code.Subcode.Value && obj.Code.Subcode.Value.Name) {
                        errorObj.Subcode = obj.Code.Subcode.Value.Name;
                    }
                }
                if (obj.Subcode) {
                    errorObj.Subcode = obj.Subcode;
                }
                if (obj.Reason) {
                    var reasonIsArray = PeopleMedia.CP.Utilities.isArray(obj.Reason);
                    if (errorObj.IsCancelled) {
                        errorObj.Text = "";
                    } else {
                        errorObj.Text = (reasonIsArray) ? obj.Reason[0] : obj.Reason;
                    }
                }
                if (typeof (field) !== 'undefined' && field !== null) {
                    errorObj.Field = field;
                }
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "convertFaultToError" });
        }
        return errorObj;
    };

    var createErrorObject = function (prop) {
        var errObj;
        try {
            var errProp = prop || {};
            errObj = $jq.extend({}, { reason: "error" }, errProp);
        } catch (ex) {
            errObj = { reason: "error" };
        }
        return errObj;
    };

    var getFirstError = function(errors) {
        try {
            var errorsIsArray = PeopleMedia.CP.Utilities.isArray(errors);
            if (!errorsIsArray) {
                return errors;
            }
            return (errors.length > 0) ? errors[0] : null;
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "getFirstError" });
            return null;
        }
    };

    var init = function(config) {
        try {
            if (config) {
                SiteID(config.siteId);
                VersionedContentRoot(config.versionedContentRoot);
                PageFolder(config.pageFolder);
                DomainName(config.domainName);
                VisitorID(config.visitorId);
                var visitorIdNumeric = parseInt(config.visitorId, 10);
                if (!isNaN(visitorIdNumeric)) {
                    SessionVisitorID(visitorIdNumeric);
                }
                if (typeof (config.searchId) !== 'undefined') {
                    SearchID(config.searchId);
                }
                AffiliateID(config.affiliateId);
                SponsorID(config.sponsorId);
                GenderSeekEnabled(config.genderSeekEnabled);
                PersistentLoginEnabled(config.persistentLoginEnabled);
                InvalidEmailDomainCheckEnabled(config.invalidEmailDomainCheckEnabled);
                // MemberID(config.memberId);
                // EncodedMemberID(config.encodedMemberId);
                GenderID(config.genderId);
                GenderSeekID(config.genderSeekId);
                Username(config.username || "");
                AutoLoginEnabled(config.autoLoginEnabled);
                ContentVersion(config.contentVersion);
                PagePath(config.pagePath);
                PageVersion(config.pageVersion);
                DomainID(config.domainId);
                ServerID(config.serverId);
                SearchCategoryID(config.searchCategoryId);
                if (typeof (config.pinnedSearchId) !== 'undefined') {
                    PinnedSearchID(config.pinnedSearchId);
                }
                if (typeof (config.pinnedSearchCategoryId) !== 'undefined') {
                    PinnedSearchCategoryID(config.pinnedSearchCategoryId);
                }
                Subdomain(config.subdomain);
                FacebookAppId(config.facebookAppId);
                EventTrackingPermitted(config.eventTrackingPermitted);
                _publicMemberId = config.publicMemberId;
            }
            initSessionValues();
            initQueryStringValues();

            initComplete();
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "init" });
        }
    };

    var initSessionValues = function() {
        var sessionCookie = null;
        try {
            sessionCookie = PeopleMedia.Net.readCookie(SessionCookieName());
            if (sessionCookie !== null) {
                var esid = sessionCookie.ESID;
                if (typeof (esid) !== 'undefined' && esid !== null) {
                    SessionId(esid);
                }
                var theme = sessionCookie.THEME;
                if (typeof (theme) !== 'undefined' && theme !== null) {
                    Theme(theme);
                }
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "initSessionValues", sessionCookie: sessionCookie });
        }
    };

    var getFacebookImportStatus = function() {
        return PeopleMedia.Net.loadState(FacebookImportCookieName());
    };

    var setFacebookImportStatus = function(status) {
        PeopleMedia.Net.saveState(FacebookImportCookieName(), status);
    };

    var clearFacebookImportStatus = function() {
        try {
            PeopleMedia.Net.eraseCookie(FacebookImportCookieName());
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "clearFacebookImportStatus" });
        }
    };

    var getFacebookAuthData = function() {
        var data = null;
        try {
            data = PeopleMedia.Net.readCookie(FacebookAuthCookieName());
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "getFacebookAuthData", data: data });
        }
        return data || {};
    };

    var setFacebookAuthData = function(authData) {
        try {
            var value = authData || {};
            PeopleMedia.Net.createCookie(FacebookAuthCookieName(), value, null);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "setFacebookAuthData", authData: authData });
        }
    };

    var initQueryStringValues = function(currentQueryString) {
        try {
            var keyValues = PeopleMedia.Net.getQueryString(currentQueryString);
            var emailGuid = PeopleMedia.Net.getQueryStringValue(keyValues, "EmailGUID");
            if (typeof(emailGuid) !== 'undefined' && emailGuid !== null && emailGuid.length === 36) {
                EmailGuid(emailGuid);
            }
            var gender = PeopleMedia.Net.getQueryStringValue(keyValues, "iam");
            if (typeof (gender) !== 'undefined' && gender !== null && gender.length > 0) {
                DefaultGenderID(gender);
            }
            var genderSeek = PeopleMedia.Net.getQueryStringValue(keyValues, "seeking");
            if (typeof (genderSeek) !== 'undefined' && genderSeek !== null && genderSeek.length > 0) {
                DefaultGenderSeekID(genderSeek);
            }
            var minAge = PeopleMedia.Net.getQueryStringValue(keyValues, "minage");
            if (typeof (minAge) !== 'undefined' && minAge !== null && minAge.length > 0) {
                DefaultMinAge(minAge);
            }
            var maxAge = PeopleMedia.Net.getQueryStringValue(keyValues, "maxage");
            if (typeof (maxAge) !== 'undefined' && maxAge !== null && maxAge.length > 0) {
                DefaultMaxAge(maxAge);
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "initQueryStringValues" });
        }
    };
        
    var initLogging = function () {
        window.onerror = handleError;
        // PeopleMedia.Diagnostics.registerExceptionHandler(handleException);
        _stacktraceScriptLoaded = true;
    };

    var executeOnReadyCallback = function(handler) {
        try {
            if (handler && !handler.processed && typeof (handler.cb) === "function") {
                handler.cb();
                handler.processed = true;
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "executeOnReadyCallback" });
        }
    };

    var executeAllOnReadyHandlers = function() {
        try {
            for (var i = 0; i < _onReadyHandlers.length; i++) {
                executeOnReadyCallback(_onReadyHandlers[i]);
            }
            _onReadyHandlers = [];
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "executeAllOnReadyHandlers" });
        }
    };

    var tryReady = function() {
        try {
            if (!_onReadyComplete && _initComplete && _ready && _stacktraceScriptLoaded) {
                _onReadyComplete = true;
                trackRegistrationPage();
                $jq(document).ready(function () {
                    executeAllOnReadyHandlers();
                });
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "tryReady" });
        }
    };

    var onReady = function (cb) {
        if (typeof (cb) === "function") {
            _onReadyHandlers.push({
                cb: cb,
                processed: false
            });
        }
        tryReady();
    };

    var ready = function() {
        _ready = true;
        tryReady();
    };

    var initComplete = function() {
        _initComplete = true;
        tryReady();
    };

    var ui_busy = function() {
        $jq('body').css('cursor', 'progress');
        $jq("div.loader").show();
    };

    var ui_done = function() {
        $jq('body').css('cursor', 'auto');
        $jq("div.loader").hide();
    };

    var getStackTrace = function(exception) {
        try {
            if (typeof (exception) !== 'undefined' && exception !== null && typeof (printStackTrace) !== 'undefined') {
                return printStackTrace({ e: exception, guess: true });
            }
        } catch (ex) {
        }
        return "";
    };

    var getDetailString = function(detail) {
        var result;
        try {
            if (typeof (detail) === 'undefined' || detail === null) {
                result = "";
            } else if (typeof (JSON) !== 'undefined') {
                result = JSON.stringify(detail);
            } else {
                result = detail;
            }
        } catch (ex) {
            result = "";
        }
        return result;
    };

    var formatErrorMessage = function(message) {
        try {
            if (typeof (message) !== 'undefined' && message !== null) {
                return message.substr(0, 200);
            }
        } catch (ex) {
        }
        return "";
    };

    var formatStackTrace = function(stacktrace) {
        try {
            if (typeof (stacktrace) !== 'undefined' && stacktrace !== null) {
                return encodeURI(stacktrace.join('\n')).substr(0, 1000);
            }
        } catch (ex) {
        }
        return "";
    };

    var formatDetailString = function(detailString) {
        try {
            if (typeof (detailString) !== 'undefined' && detailString !== null) {
                return encodeURI(detailString);
            }
        } catch (ex) {
        }
        return "";
    };

    var handleException = function (exception, detail, completeFunction) {
        try {
            var stacktrace = "";
            var message = "";
            var exceptionTypeName = "";
            var url = "";
            var lineNumber = 0;
            if (typeof (exception) !== 'undefined' && exception !== null) {
                message = exception.message ? exception.message : ((typeof (exception) === 'string') ? exception : "");
                exceptionTypeName = exception.name ? exception.name : "";
                url = exception.fileName ? exception.fileName : "";
                lineNumber = exception.lineNumber ? exception.lineNumber : 0;
                stacktrace = getStackTrace(exception);
            }
            _logExceptionPost("LPS Client", message, exceptionTypeName, url, lineNumber, stacktrace, detail, completeFunction);
        }
        catch (ex) {
        }
    };

    var handleError = function(message, url, lineNumber) {
        try {
            _logExceptionPost("LPS Client Unknown", message, "", url, lineNumber, null, "", null);
        } catch (ex) {
        }
    };

    var logAjax = function (errorObj) {
        if (typeof (errorObj) === "undefined" || errorObj === null) {
            return;
        }
        var message = "Ajax request failure";
        var url;
        var jqXHR;
        var textStatus;
        var errorThrown;
        var completeFunction;
        var detail = $jq.extend({}, errorObj);
        if (detail.message) {
            message = detail.message;
            delete detail.message;
        }
        if (detail.url) {
            url = detail.url;
            delete detail.url;
        }
        if (detail.jqXHR) {
            jqXHR = detail.jqXHR;
            delete detail.jqXHR;
        }
        if (detail.textStatus) {
            textStatus = detail.textStatus;
            delete detail.textStatus;
        }
        if (detail.errorThrown) {
            errorThrown = detail.errorThrown;
            delete detail.errorThrown;
        }
        if (typeof (detail.completeFunction) === "function") {
            completeFunction = detail.completeFunction;
            delete detail.completeFunction;
        }
        return logAjaxError(message, url, detail, jqXHR, textStatus, errorThrown, completeFunction);
    };

    var logAjaxError = function(message, url, detail, jqXHR, textStatus, errorThrown, completeFunction) {
        try {
            // don't try to log for these urls
            var lowerUrl = (url || "").toLowerCase();
            if (lowerUrl.indexOf('/v3/lps/api/log') < 0 &&
                lowerUrl.indexOf('/v3/log/logexception') < 0 &&
                lowerUrl.indexOf('/v3/log/api/logexceptionpost') < 0 &&
                lowerUrl.indexOf('/v3/lps/api/logmessagepost') < 0 &&
                lowerUrl.indexOf('/v3/lps/api/trackmemberregistrationevent') < 0 &&
                lowerUrl.indexOf('/v3/lps/api/signinstatus') < 0 &&
                lowerUrl.indexOf('/v3/ajax/refreshsession') < 0) {
                try {
                    // update detail
                    if (typeof(detail) === 'undefined' || detail === null) {
                        detail = {};
                    }
                    if (textStatus) {
                        detail.textStatus = textStatus;
                    }
                    if (errorThrown) {
                        detail.errorThrown = errorThrown;
                    }
                    if (jqXHR) {
                        if (jqXHR.readyState) {
                            detail.readystate = jqXHR.readyState;
                        }
                        if (jqXHR.status) {
                            detail.jqXHRStatus = jqXHR.status;
                        }
                        if (jqXHR.statusText) {
                            detail.jqXHRStatusText = jqXHR.statusText;
                        }
                        if (jqXHR.responseText) {
                            detail.jqXHRResponseText = jqXHR.responseText.substr(0, 1000);
                        }
                    }
                    if (!detail.pagePath) {
                        detail.pagePath = PagePath();
                    }
                    if (!detail.pageVersion) {
                        detail.pageVersion = PageVersion();
                    }
                    if (!detail.href) {
                        detail.href = window.location.href;
                    }
                } catch (ex) {
                }
                _logExceptionPost("LPS Client", message, "", url, 0, null, detail, completeFunction);
            }
        } catch (ex) {
        }
    };

    var logMessage = function(message, detail, completeFunction) {
        try {
            var url = window.location.href;
            var detailString = getDetailString(detail);
            var timeoutValue = 30000;
            var errorData = {
                "message": formatErrorMessage(message),
                "url": url,
                "pagePath": PagePath() || '',
                "pageVersion": PageVersion() || '',
                "additionalInfo": formatDetailString(detailString),
                "SkipCSSVerif": "HTMLEditor"
            };
            jQuery.ajax({
                type: 'POST',
                url: "/v3/lps/api/LogMessagePost",
                data: errorData,
                dataType: "json",
                success: function(result) {
                    try {
                        if (result && result.redirect) {
                            location.href = result.redirect;
                        }
                    } catch (ex) {
                    }
                },
                complete: function() {
                    finishCompletion(completeFunction);
                },
                async: true,
                cache: false,
                timeout: timeoutValue
            });
        } catch (ex) {
        }
    };
        
    var _logExceptionPost = function (appName, message, exceptionTypeName, url, lineNumber, stacktrace, detail, completeFunction) {
        try {
            var detailString = getDetailString(detail);
            if (stacktrace === null) {
                stacktrace = [];
            }
            url = url === "" ? window.location.href : url;
            lineNumber = typeof(lineNumber) !== 'undefined' && lineNumber !== null ? lineNumber : 0;
            for (var i = 0; i < stacktrace.length; ++i) {
                stacktrace[i] = 'at ' + stacktrace[i];
            }
            var timeoutValue = 30000;
            var errorData = {
                "appName": appName,
                "message": formatErrorMessage(message),
                "exceptionTypeName": exceptionTypeName,
                "url": url,
                "lineNumber": lineNumber,
                "pagePath": PagePath() || '',
                "pageVersion": PageVersion() || '',
                "stacktrace": formatStackTrace(stacktrace),
                "additionalInfo": formatDetailString(detailString),
                "SkipCSSVerif": "HTMLEditor"
            };
            jQuery.ajax({
                type: "POST",
                url: "/v3/log/logexception",
                data: errorData,
                dataType: "json",
                success: function (result) {
                    try {
                        if (result && result.redirect) {
                            location.href = result.redirect;
                        }
                    }
                    catch (ex) {
                    }
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                complete: function () {
                    finishCompletion(completeFunction);
                }
            });
        }
        catch (ex) {
        }
    };

    var formatRegistrationEventError = function(error) {
        var result = "";
        try {
            if (error) {
                var errorIsArray = PeopleMedia.CP.Utilities.isArray(error);
                if (errorIsArray) {
                    if (error.length > 0) {
                        result = "Error|" + (error[0].Field || "") + "|" + (error[0].Text || "");
                    } else {
                        result = "Error||";
                    }
                } else {
                    result = "Error|" + (error.Field || "") + "|" + (error.Text || "");
                }
            }
        } catch (ex) {
        }
        return result;
    };

    var formatRegistrationEventInfo = function(element, addlInfo) {
        try {
            var value = PeopleMedia.CP.Utilities.simpleArrayToString(element);
            if (!PeopleMedia.CP.Utilities.isNullOrMissing(addlInfo)) {
                value = value + ":" + PeopleMedia.CP.Utilities.simpleArrayToString(addlInfo);
            }
            return value;
        } catch (ex) {
        }
    };

    var trackRegistrationPage = function(options) {
        try {
            var alreadyTracked = EventTrackingEnabled();
            if (!alreadyTracked) {
                var eventData = {
                    "PagePath": PagePath(),
                    "PageVersion": PageVersion()
                };
                if (PageAlias()) {
                    eventData.PageAlias = PageAlias();
                }
                EventTrackingEnabled(true);
                trackMemberRegistrationEvents(eventData);
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackRegistrationPage" });
        }
    };

    var trackRegistrationSuccess = function(data, completeFunction) {
        try {
            var id = (typeof (data) !== 'undefined' && data !== null && data.NickName) ? data.NickName : "";
            trackMemberRegistrationEvent("RegistrationSuccess", id, completeFunction);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackRegistrationSuccess" }, finishCompletion(completeFunction));
        }
    };

    var trackRegistrationCompleted = function(url, completeFunction) {
        try {
            if (typeof url !== 'undefined' && url !== null && url.length > 0) {
                var urlLower = url.toLowerCase();
                if (urlLower.indexOf("/v3/lps") < 0 && urlLower.indexOf("cpp=") < 0) {
                    trackMemberRegistrationEvent("RegistrationCompleted", url, completeFunction);
                } else {
                    finishCompletion(completeFunction);
                }
            }
        } catch (ex) {
            handleException(ex,
                { oid: Oid, method: "trackRegistrationCompleted" },
                finishCompletion(completeFunction));
        }
    };

    var trackRegistrationFailure = function(errors, completeFunction) {
        try {
            trackMemberRegistrationEvent("RegistrationFailed",
                formatRegistrationEventError(errors),
                completeFunction);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackRegistrationFailure" }, finishCompletion(completeFunction));
        }
    };

    var getRegistrationEventAnalyticsAction = function() {
        try {
            var isSearchIdPaid = !!PeopleMedia.Configuration.searchIdIsPaid;
            var isMobile = !!PeopleMedia.Configuration.isMobile;
            return ((isSearchIdPaid) ? "Paid" : "Organic") + " " + ((isMobile) ? "Mobile" : "Desktop") + " Flow";
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "getRegistrationEventAnalyticsAction" });
            return "Organic Desktop Flow";
        }
    };

    var trackRegistrationEventAnalytics = function() {
        try {
            // uses global variable from Google Analytics snippet in header; skip logic if snippet is not there
            if (typeof (_gaq) !== "undefined" && _gaq !== null) {
                var category = "Homepage Callout";
                var action = getRegistrationEventAnalyticsAction();
                var trackingLabel = "Registration Submit";

                var trackEvent = document.createElement('div');
                trackEvent.innerHTML = "_gaq.push([\"_trackEvent\",\"" + category + "\",\"" + action + "\",\"" + trackingLabel + "\"]);";
                document.getElementsByTagName("head")[0].appendChild(trackEvent);

                _gaq.push(["_trackEvent", category, action, trackingLabel]);
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackRegistrationEventAnalytics" });
        }
    };

    var formatTrackedRegItem = function (value) {
        var result = "";
        try {
            if (typeof (value) === "undefined") {
                result = "(undefined)";
            } else if (value === null) {
                result = "(null)";
            } else {
                result = value.toString().trim();
            }
        } catch (ex) {
        }
        return result;
    };

    var getFormattedTrackedRegItems = function (values) {
        var result = "";
        try {
            var formattedItems = [];
            if (PeopleMedia.CP.Utilities.isArray(values)) {
                for (var i = 0; i < values.length; i++) {
                    var currentItem = formatTrackedRegItem(values[i]);
                    if (formattedItems.indexOf(currentItem) < 0) {
                        formattedItems.push(currentItem);
                    }
                }
            } else {
                formattedItems.push(formatTrackedRegItem(values));
            }
            result = PeopleMedia.CP.Utilities.simpleArrayToString(formattedItems);
        } catch (ex) {
        }
        return result;
    };

    var formatRegistrationEventAlias = function (element, action) {
        var str = "";
        try {
            str = (PageAlias() || "");
            if (element) {
                str += "/" + element;
            }
            if (action) {
                str += "/" + action;
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "formatRegistrationEventAlias" });
        }
        return str;
    };

    var trackRegistrationEventShowRegQuestionsWithCategory = function (questionIds, category, completeFunction) {
        try {
            var value = getFormattedTrackedRegItems(questionIds);
            if (typeof (category) !== "undefined" && category !== null && category.length > 0) {
                value += " " + category;
            }
            trackMemberRegistrationEvent("ShowRegQuestions", value, completeFunction);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackRegistrationEventShowRegQuestionsWithCategory" }, finishCompletion(completeFunction));
        }
    };

    var trackRegistrationEventShowRegQuestions = function (questionIds, completeFunction) {
        try {
            var value = getFormattedTrackedRegItems(questionIds);
            trackMemberRegistrationEvent("ShowRegQuestions", value, completeFunction);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackRegistrationEventShowRegQuestions" }, finishCompletion(completeFunction));
        }
    };

    var trackRegistrationEventShowRegErrors = function (errors, completeFunction) {
        try {
            var value = getFormattedTrackedRegItems(errors);
            trackMemberRegistrationEvent("ShowRegErrors", value, completeFunction);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackRegistrationEventShowRegErrors" }, finishCompletion(completeFunction));
        }
    };

    var trackRegistrationEventShow = function(element, addlInfo, completeFunction) {
        try {
            var value = formatRegistrationEventInfo(element, addlInfo);
            trackMemberRegistrationEvent("Show", value, completeFunction);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackRegistrationEventShow" }, finishCompletion(completeFunction));
        }
    };

    var trackRegistrationEventShowWithAliasPanelViewed = function (element, addlInfo, panelAlias, completeFunction) {
        try {
            var showValue = formatRegistrationEventInfo(element, addlInfo);
            var viewedValue = formatRegistrationEventAlias(panelAlias, "viewed");
            trackMemberRegistrationEvents({
                "Show": showValue,
                "Viewed": viewedValue
            }, completeFunction);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackRegistrationEventShow" }, finishCompletion(completeFunction));
        }
    };

    var trackRegistrationEventAliasPanelViewed = function (panelAlias, completeFunction) {
        try {
            var value = formatRegistrationEventAlias(panelAlias, "viewed");
            trackMemberRegistrationEvent("Viewed", value, completeFunction);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackRegistrationEventAliasPanelViewed" }, finishCompletion(completeFunction));
        }
    };

    var trackRegistrationEventAliasButtonTapped = function (panelAlias, buttonAlias, completeFunction) {
        try {
            var value = formatRegistrationEventAlias(panelAlias, buttonAlias + "tapped");
            trackMemberRegistrationEvent("Tapped", value, completeFunction);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackRegistrationEventAliasButtonTapped" }, finishCompletion(completeFunction));
        }
    };

    var trackRegistrationEventOneClickGreetingSubmit = function(greetingItem, textValue, completeFunction) {
        try {
            var value = greetingItem.greetingId.toString() +
                ":" +
                greetingItem.greetingType.toString() +
                ":" +
                greetingItem.greetingTitle;
            var trimmedText = (textValue || "").trim();
            if (trimmedText !== greetingItem.greetingDescription) {
                value += " (modified)";
            }
            trackMemberRegistrationEvent("OneClickGreetingSubmit", value, completeFunction);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackRegistrationEventOneClickGreetingSubmit" }, finishCompletion(completeFunction));
        }
    };

    var trackMemberRegistrationEvent = function(key, value, completeFunction) {
        try {
            if (EventTrackingPermitted() && EventTrackingEnabled()) {
                var timeoutValue = 3000;
                jQuery.ajax({
                    type: "POST",
                    url: "/v3/lps/api/TrackMemberRegistrationEvent",
                    data: {
                        "visitorId": VisitorID(),
                        "key": key || "",
                        "value": value || "",
                        "SkipCSSVerif": "HTMLEditor"
                    },
                    dataType: "json",
                    async: true,
                    cache: false,
                    timeout: timeoutValue,
                    complete: function () {
                        finishCompletion(completeFunction);
                    }
                });
            } else {
                finishCompletion(completeFunction);
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackMemberRegistrationEvent" }, finishCompletion(completeFunction));
        }
    };

    var trackMemberRegistrationEvents = function(trackedValues, completeFunction) {
        try {
            if (EventTrackingPermitted() && EventTrackingEnabled()) {
                var timeoutValue = 3000;
                var detailString = getDetailString(trackedValues);
                jQuery.ajax({
                    type: "POST",
                    url: "/v3/lps/api/TrackMemberRegistrationEvents",
                    data: {
                        "visitorId": VisitorID(),
                        "trackedValues": detailString,
                        "SkipCSSVerif": "HTMLEditor"
                    },
                    dataType: "json",
                    async: true,
                    cache: false,
                    timeout: timeoutValue,
                    complete: function () {
                        finishCompletion(completeFunction);
                    }
                });
            } else {
                finishCompletion(completeFunction);
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "trackMemberRegistrationEvents" }, finishCompletion(completeFunction));
        }
    };

    var finishCompletion = function(completeFunction) {
        try {
            if (typeof(completeFunction) === 'function') {
                completeFunction();
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "finishCompletion" });
        }
    };

    var addEnterKeyAction = function (containerName, targetName) {
        try {
            if (!containerName || !targetName) {
                return;
            }

            var selectorTarget = (targetName.charAt(0) !== "#" && targetName.charAt(0) !== ".") ? '#' + targetName : targetName;
            var selectorContainer = (containerName.charAt(0) !== "#" && containerName.charAt(0) !== ".") ? '#' + containerName : containerName;

            var btn = $jq(selectorTarget);
            btn.parents(selectorContainer).on("keypress", function (e) {
                try {
                    if (e.which === 13 && e.target.type !== 'textarea') {
                        btn.each(function (index, element) {
                            try {
                                var visible = true;
                                var b = $jq(element);
                                b.parents(':hidden').each(function (index, parent) {
                                    visible = false;
                                });
                                if (visible) {
                                    b.trigger('click');
                                }
                            }
                            catch (ex) {
                                handleException(ex);
                            }
                        });
                        return false;
                    }
                }
                catch (ex) {
                    handleException(ex, { oid: Oid, method: "addEnterKeyAction enter keypress" });
                }
            });
        }
        catch (ex) {
            handleException(ex, { oid: Oid, method: "addEnterKeyAction" });
        }
    };

    var getSiteUrl = function(path, currentQueryString) {
        var useSSL;
        try {
            var basePath = PeopleMedia.Net.getUrlWithoutQueryString(path);
            var qsPath = PeopleMedia.Net.getQueryStringFromUrl(path);
            var qs = '';

            var domainName = PeopleMedia.Net.getDomainName(DomainName()).toLowerCase();
            var sessionId = SessionId();
            var visitorId = VisitorID();
            var subdomain = Subdomain();

            if (domainName.split('.').length <= 2) {
                domainName = subdomain + "." + domainName;
            }

            var qsPathKeyValues = PeopleMedia.Net.parseQueryString(qsPath);
            qsPath = PeopleMedia.Net.createQueryString(qsPathKeyValues, ['CPSessionID', 'VisitorID']);
            if (qsPath.length > 0 && (qsPath.charAt(0) === '?' || qsPath.charAt(0) === '&')) {
                qsPath = qsPath.substring(1);
            }
            var reservedKeys = PeopleMedia.Net.getQueryStringKeys(qsPathKeyValues);
            reservedKeys.push('cpp',
                'CPSessionID',
                'VisitorID',
                'notrack',
                'code',
                'username',
                'password',
                'email',
                'emailaddress');

            // load current queryString into collection (or use currentQueryString parameter to override current querystring)
            var qsCurrentKeyValues = PeopleMedia.Net.getQueryString(currentQueryString);
            var qsCurrent = PeopleMedia.Net.createQueryString(qsCurrentKeyValues, reservedKeys);
            if (typeof (sessionId) !== 'undefined' && sessionId !== null) {
                qsCurrent = PeopleMedia.Net.addQueryStringValue(qsCurrent, "CPSessionID", sessionId);
            }
            if (typeof (visitorId) !== 'undefined' && visitorId !== null) {
                qsCurrent = PeopleMedia.Net.addQueryStringValue(qsCurrent, "VisitorID", visitorId);
            }
            if (qsCurrent.length > 0 && (qsCurrent.charAt(0) === '?' || qsCurrent.charAt(0) === '&')) {
                qsCurrent = qsCurrent.substring(1);
            }

            // get merged querystrings
            if (qsPath.length > 0 || qsCurrent.length > 0) {
                qs = '?' +
                    ((qsPath.length > 0) ? qsPath : '') +
                    ((qsPath.length > 0 && qsCurrent.length > 0) ? '&' : '') +
                    qsCurrent;
            }

            // remove host and domain from basePath
            var protocolIndex = basePath.indexOf('://');
            if (protocolIndex >= 0 || basePath.substring(0, subdomain.length + 1).toLowerCase() === (subdomain + '.')) {
                var baseStart = (protocolIndex >= 0) ? (protocolIndex + 3) : 0;
                var slashStart = basePath.indexOf('/', baseStart);
                if (slashStart >= 0) {
                    basePath = basePath.substring(slashStart + 1);
                } else {
                    basePath = "";
                }
            }
            useSSL = "https:" === document.location.protocol;
            return (useSSL ? "https://" : "http://") +
                domainName +
                ((basePath.length > 0 && basePath.charAt(0) !== '/') ? '/' : '') +
                basePath +
                qs;
        } catch (ex) {
            useSSL = "https:" === document.location.protocol;
            handleException(ex, { oid: Oid, method: "getSiteUrl" });
            return (useSSL ? "https://" : "http://") + DomainName();
        }
    };

    var startLoginFlow = function (options) {
        try {
            getSelfProfileComplete({}, function (profileErr, getSelfProfileCompleteResponse) {
                var url = "/v3/home";
                if (profileErr === null && getSelfProfileCompleteResponse && getSelfProfileCompleteResponse.AutoLoginDetail) {
                    var loginSource = "XX";
                    var qs = null;
                    if (options) {
                        if (options.loginSource && loginSource.length === 2) {
                            loginSource = options.loginSource;
                        }
                        if (options.ofp) {
                            qs = "&ofp=" + options.ofp;
                            if (options.ofp.toLowerCase() === "quicksearch" && options.ofpParameters) {
                                qs += "|" + options.ofpParameters;
                            }
                        } else if (options.loginFlowFinishPhotoUpload === true) {
                            qs = "&lpsofp=fse";
                        } else if (options.quickSearch === true) {
                            qs = "&lpsofp=xx&UPN=&ofp=QuickSearch";
                        }
                    }
                    url = getSiteUrl("/v3/autologin?login=" + loginSource + getSelfProfileCompleteResponse.AutoLoginDetail, qs);
                }
                trackRegistrationCompleted(url,
                    function () {
                        window.location.href = url;
                    });
            });
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "gotoSite" });
        }
    };

    var gotoSite = function(path, queryString) {
        try {
            var url = getSiteUrl(path, queryString);
            trackRegistrationCompleted(url,
                function() {
                    window.location.href = url;
                });
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "gotoSite" });
        }
    };

    var getCpUrl = function(cpPage, queryString) {
        var path;
        try {
            path = "/v3/lps?cpp=" + ((typeof(cpPage) !== 'undefined' && cpPage !== null) ? cpPage : "");
            return getSiteUrl(path, queryString);
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "getCpUrl" });
            return path;
        }
    };

    var gotoCPPage = function(cpPage, queryString) {
        try {
            var path = getCpUrl(cpPage, queryString);
            window.location.href = path;
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "gotoCPPage" });
        }
    };

    var convertGenderToID = function(gender) {
        try {
            if (typeof (gender) !== 'undefined' && gender !== null) {
                if (gender === 1 || gender === 2) {
                    return gender;
                }
                var genderLower = gender.toString().toLowerCase();
                if (genderLower === "1" || genderLower === "m" || genderLower === "male") {
                    return 1;
                }
                if (genderLower === "2" || genderLower === "f" || genderLower === "female") {
                    return 2;
                }
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "convertGenderToID" });
        }
        return null;
    };

    var isLoggedIntoSite = function(onComplete) {
        try {
            ui_busy();
            var url = "/v3/lps/api/signinstatus";
            var startTime = new Date().getTime();
            var timeoutValue = 30000;
            jQuery.ajax({
                type: "GET",
                url: url,
                data: {},
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function(loginData) {
                    ui_done();
                    var username = "";
                    var userId = "";
                    var token = null;
                    var signinStatus = false;
                    if (loginData && loginData.status === "Success") {
                        signinStatus = !!loginData.signInStatus;
                        token = loginData.token;
                        username = (loginData.token && loginData.token.username) ? loginData.token.username : "";
                        userId = (loginData.token && loginData.token.userId) ? loginData.token.userId : "";
                    }
                    onComplete(null, {
                        signinStatus: signinStatus,
                        token: token,
                        userId: userId,
                        username: username
                    });
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    ui_done();
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    var data = convertAjaxErrorToFault(jqXHR, textStatus, errorThrown);
                    if (!data.IsCancelled) {
                        logAjax({ oid: Oid, method: "isLoggedIntoSite", url: url, duration: duration, jqXHR: jqXHR, textStatus: textStatus, errorThrown: errorThrown });
                    }
                    onComplete(data, null);
                },
                async: true,
                cache: false,
                timeout: timeoutValue
            });
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "isLoggedIntoSite" });
            onComplete(createGeneralError(), null);
        }
    };

    var gotoPromoUpgrade = function () {
        try {
            isLoggedIntoSite(function (loginErr, loginData) {
                var keyValues = PeopleMedia.Net.getQueryString();
                var loginQS = PeopleMedia.Net.getQueryStringValue(keyValues, "login");
                var isLoggedIn = (loginErr === null && loginData && loginData.signinStatus);
                if (isLoggedIn ||
                    typeof (loginQS) === "undefined" ||
                    loginQS === null ||
                    loginQS.length <= 2) {
                    gotoSite("/v3/upgrade");
                    return;
                }
                gotoSite("/v3/autologin?lpsal=true&ofp=u");
            });
        }
        catch (ex) {
            handleException(ex, { oid: Oid, method: "gotoPromoUpgrade" });
        }
    };

    var apiMemberRequest = function(memberRequestData, onComplete) {
        try {
            memberRequestData = memberRequestData || {};
            var memberRequestDataWithContext = $jq.extend({}, memberRequestData, {
                Context: {}
            });

            authenticateRegisteredMembers(
                function(authenticateRegisteredMembersErr, authenticateRegisteredMembersResponse) {
                    var memberRequestDataWithTokenAndContext;
                    if (authenticateRegisteredMembersErr === null && authenticateRegisteredMembersResponse) {
                        // authentication successful or already logged in
                        memberRequestDataWithTokenAndContext = $jq.extend({},
                            memberRequestDataWithContext,
                            {
                                Token: authenticateRegisteredMembersResponse.token || {}
                            });
                        onComplete(null, memberRequestDataWithTokenAndContext);
                    } else {
                        // authentication failed
                        onComplete(authenticateRegisteredMembersErr, memberRequestDataWithContext);
                    }
                });

        } catch (ex) {
            // unable to retrieve login status; treat as login failure
            handleException(ex, { oid: Oid, method: "apiMemberRequest" });
            onComplete(createGeneralError(), memberRequestData);
        }
    };

    var authenticateRegisteredMembers = function(completeFunction) {
        try {
            var timeout = 60000;
            var startTime = new Date().getTime();
            var url = "/v3/lps/api/AuthenticateWithPersistentLogin";

            jQuery.ajax({
                headers: PeopleMedia.CP.LpsPostbackHeader(),
                type: 'POST',
                url: url,
                data: {},
                dataType: "json",
                success: function(response) {
                    if (response && response.redirect) {
                        location.href = response.redirect;
                    } else if (response && response.status === "Success") {
                        if (response.publicMemberId) {
                            _publicMemberId = response.publicMemberId;
                        } else {
                            _publicMemberId = "";
                        }
                        if (response.gender) {
                            GenderID(response.gender);
                        }
                        if (response.seeking) {
                            GenderSeekID(response.seeking);
                        }
                        if (response.visitorId && response.visitorId > 0) {
                            SessionVisitorID(response.visitorId);
                        }
                        if (response.username) {
                            Username(response.username);
                        } else {
                            Username(null);
                        }
                    } else {
                        _publicMemberId = "";
                        // EncodedMemberID(null);
                        Username(null);
                    }
                    completeFunction(null, response);
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    var data = convertAjaxErrorToFault(jqXHR, textStatus, errorThrown);
                    if (!data.IsCancelled) {
                        logAjax({ oid: Oid, method: "authenticateRegisteredMembers", url: url, duration: duration, jqXHR: jqXHR, textStatus: textStatus, errorThrown: errorThrown });
                    }
                    completeFunction(data, null);
                },
                async: true,
                cache: false,
                timeout: timeout
            });
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "authenticateRegisteredMembers" });
            completeFunction(createGeneralError(), null);
        }
    };

    var getSelfProfileComplete = function(getSelfProfileCompleteRequest, onComplete) {
        try {
            apiMemberRequest(getSelfProfileCompleteRequest,
                function(err, apiMemberRequestResponse) {
                    if (err === null &&
                        apiMemberRequestResponse) {

                        var url = "/v3/lps/api/SelfProfileComplete";
                        var ajaxData = {
                            "DetailLevel": 1
                        };

                        var startTime = new Date().getTime();
                        var timeoutValue = 60000;
                        jQuery.ajax({
                            headers: PeopleMedia.CP.LpsPostbackHeader(),
                            type: "POST",
                            url: url,
                            data: JSON.stringify(ajaxData),
                            dataType: "json",
                            contentType: "application/json; charset=utf-8",
                            success: function (getSelfProfileCompleteResponse) {
                                if (getSelfProfileCompleteResponse && getSelfProfileCompleteResponse.publicMemberId) {
                                    _publicMemberId = getSelfProfileCompleteResponse.publicMemberId;
                                }
                                var detailResponse = $jq.extend({}, getSelfProfileCompleteResponse, {
                                    requestData: apiMemberRequestResponse
                                });
                                onComplete(null, detailResponse);
                            },
                            error: function(jqXHR, textStatus, errorThrown) {
                                var endTime = new Date().getTime();
                                var duration = (endTime - startTime);
                                var data = convertAjaxErrorToFault(jqXHR, textStatus, errorThrown);
                                if (!data.IsCancelled) {
                                    logAjax({ oid: Oid, method: "getSelfProfileComplete", url: url, duration: duration, jqXHR: jqXHR, textStatus: textStatus, errorThrown: errorThrown });
                                }
                                onComplete(data, null);
                            },
                            async: true,
                            cache: false,
                            timeout: timeoutValue
                        });

                    } else {
                        onComplete(createGeneralError(), null);
                    }
                });
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "getSelfProfileComplete" });
            if (typeof (onComplete) === "function") {
                onComplete(createGeneralError(), null);
            }
        }
    };

    var convertSelfProfileCompleteToCommunityMember = function(selfProfileCompleteResponse, memberId, emailAddress) {
        var result = {};
        try {
            result.Identifier = (memberId) ? memberId.toString() : "";
            result.EmailAddress = (emailAddress) ? emailAddress : "";
            if (selfProfileCompleteResponse) {
                if (selfProfileCompleteResponse.AutoLoginDetail) {
                    result.AutoLoginUrl =
                        "https://" +
                        window.location.hostname +
                        "/v3/autologin?login=XX" +
                        selfProfileCompleteResponse.AutoLoginDetail;
                }

                if (selfProfileCompleteResponse.Username) {
                    result.NickName = selfProfileCompleteResponse.Username;
                }
                if (selfProfileCompleteResponse.EncodedMemberId) {
                    result.EncodedIdentifier = selfProfileCompleteResponse.EncodedMemberId;
                }
                if (selfProfileCompleteResponse.SiteId) {
                    result.SiteId = selfProfileCompleteResponse.SiteId;
                }
                if (selfProfileCompleteResponse.Gender) {
                    result.Gender = selfProfileCompleteResponse.Gender;
                }
                if (selfProfileCompleteResponse.SeekingGender) {
                    result.SeekingGender = selfProfileCompleteResponse.SeekingGender;
                }

                if (selfProfileCompleteResponse.publicMemberId) {
                    result.PublicMemberId = selfProfileCompleteResponse.publicMemberId;
                }
                // assume user creation was valid
                result.IsDuplicateEmailAddressOnSisterSite = false;
                result.IsDuplicateEmailAddressUsernameAndPasswordOnSisterSite = false;
                result.SisterSiteID = 0;
                result.SisterSiteName = null;
                if (selfProfileCompleteResponse.requestData) {
                    if (selfProfileCompleteResponse.requestData.Context) {
                        result.Context = selfProfileCompleteResponse.requestData.Context;
                    }
                    if (selfProfileCompleteResponse.requestData.Token) {
                        result.Token = selfProfileCompleteResponse.requestData.Token;
                    }
                }
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "convertSelfProfileCompleteToCommunityMember" });
        }
        return result;
    };

    var createGeneralError = function(errMessage) {
        return { Field: "", Text: errMessage || "Error" };
    };

    // was PeopleMedia.UI.loadDDL
    var loadDDL = function(ddlId, data, valuePropertyName, textPropertyName) {
        try {
            var ddl = document.getElementById(ddlId);
            $jq.each(data,
                function(idx, item) {
                    try {
                        var o = document.createElement("option");
                        o.text = item[textPropertyName];
                        o.value = item[valuePropertyName];
                        try {
                            ddl.add(o, null);
                        } catch (ex) { // ie only
                            ddl.add(o);
                        }
                    } catch (ex) {
                        PeopleMedia.Diagnostics.handleException(ex, { oid: Oid, method: "loadDDL each" });
                    }
                });
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "loadDDL" });
        }
    };

    // was PeopleMedia.UI.loadAgeDropDownList
    var loadAgeDropDownList = function(name) {
        try {
            if ($jq('#' + name)) {
                var dropDown = $jq('#' + name);
                var selected = $jq('#' + name).val();
                dropDown.empty();
                for (var i = 18; i <= 120; i++) {
                    dropDown.append("<option value='" + i + "'>" + i + "</option>");
                }
                $jq('#' + name).val(selected);
            }
        } catch (ex) {
            handleException(ex, { oid: Oid, method: "loadAgeDropDownList" });
        }
    };

    var initDefaultForm = function () {
        try {
            addEnterKeyAction('.defaultForm', '.defaultButton');
            loadAgeDropDownList('minage');
            loadAgeDropDownList('maxage');
        }
        catch (ex) {
            handleException(ex, { oid: Oid, method: "initDefaultForm" });
        }
    };

    var getPublicMemberProperties = function () {
        return {
            SiteId: _siteID,
            PublicMemberId: _publicMemberId,
            Username: _username,
            GenderId: _genderID,
            GenderSeekId: _genderSeekID
        };
    };

    return {
        get Member() { return getPublicMemberProperties(); },
        DomainName: DomainName,
        Subdomain: Subdomain,
        SiteID: SiteID,
        SearchID: SearchID,
        AffiliateID: AffiliateID,
        VisitorID: VisitorID,
        SessionVisitorID: SessionVisitorID,
        GenderID: GenderID,
        GenderSeekID: GenderSeekID,
        Username: Username,
        CpServer: CpServer,
        AutoLoginEnabled: AutoLoginEnabled,
        PersistentLoginEnabled: PersistentLoginEnabled,
        GenderSeekEnabled: GenderSeekEnabled,
        InvalidEmailDomainCheckEnabled: InvalidEmailDomainCheckEnabled,
        VersionedContentRoot: VersionedContentRoot,
        DefaultGenderID: DefaultGenderID,
        DefaultGenderSeekID: DefaultGenderSeekID,
        AjaxErrorMessage: AjaxErrorMessage,
        FacebookAppId: FacebookAppId,
        FacebookImportReset: FacebookImportReset,
        FacebookImportSelected: FacebookImportSelected,
        FacebookImportAttempted: FacebookImportAttempted,
        FacebookImportFailed: FacebookImportFailed,
        FacebookImportGreetingFailed: FacebookImportGreetingFailed,
        FacebookImportPhotoFailed: FacebookImportPhotoFailed,
        FacebookImportMember: FacebookImportMember,
        FacebookAuthData: FacebookAuthData,
        convertAjaxErrorToFault: convertAjaxErrorToFault,
        convertFaultToError: convertFaultToError,
        getFirstError: getFirstError,
        init: init,
        initLogging: initLogging,
        onReady: onReady,
        ready: ready,
        ui_busy: ui_busy,
        ui_done: ui_done,
        handleException: handleException,
        logAjax: logAjax,
        logMessage: logMessage,
        formatRegistrationEventError: formatRegistrationEventError,
        formatRegistrationEventAlias: formatRegistrationEventAlias,
        trackRegistrationPage: trackRegistrationPage,
        trackRegistrationSuccess: trackRegistrationSuccess,
        trackRegistrationFailure: trackRegistrationFailure,
        trackRegistrationEventShowRegQuestionsWithCategory: trackRegistrationEventShowRegQuestionsWithCategory,
        trackRegistrationEventShowRegQuestions: trackRegistrationEventShowRegQuestions,
        trackRegistrationEventShowRegErrors: trackRegistrationEventShowRegErrors,
        trackRegistrationEventShow: trackRegistrationEventShow,
        trackRegistrationEventShowWithAliasPanelViewed: trackRegistrationEventShowWithAliasPanelViewed,
        trackRegistrationEventAliasPanelViewed: trackRegistrationEventAliasPanelViewed,
        trackRegistrationEventAliasButtonTapped: trackRegistrationEventAliasButtonTapped,
        trackRegistrationEventOneClickGreetingSubmit: trackRegistrationEventOneClickGreetingSubmit,
        trackMemberRegistrationEvent: trackMemberRegistrationEvent,
        trackMemberRegistrationEvents: trackMemberRegistrationEvents,
        trackRegistrationEventAnalytics: trackRegistrationEventAnalytics,
        finishCompletion: finishCompletion,
        addEnterKeyAction: addEnterKeyAction,
        getSiteUrl: getSiteUrl,
        startLoginFlow: startLoginFlow,
        gotoSite: gotoSite,
        gotoCPPage: gotoCPPage,
        gotoPromoUpgrade: gotoPromoUpgrade,
        authenticateRegisteredMembers: authenticateRegisteredMembers,
        getSelfProfileComplete: getSelfProfileComplete,
        convertSelfProfileCompleteToCommunityMember: convertSelfProfileCompleteToCommunityMember,
        createGeneralError: createGeneralError,
        createErrorObject: createErrorObject,
        initDefaultForm: initDefaultForm,
        loadDDL: loadDDL,
        ClientMessages: ClientMessages,
        LpsPostbackHeader: LpsPostbackHeader,
        PageAlias: PageAlias
    };
})(jQuery);

PeopleMedia.CP.Messaging = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.CP.Messaging";
    var _messages = null;
    var _handlers = [];

    var addHandler = function (cb) {
        try {
            if (typeof (cb) === "function") {
                _handlers.push(cb);
            }
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "addHandler" });
        }
    };

    var removeHandler = function(cb) {
        try {
            if (typeof (cb) === "function") {
                for (var i = 0; i < _handlers.length; ++i) {
                    if (_handlers[i] === cb) {
                        _handlers.splice(i, 1);
                        break;
                    }
                }
            }
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "removeHandler" });
        }
    };

    var invoke = function() {
        try {
            var result;
            var results = [];
            for (var i = 0; i < _handlers.length; ++i) {
                var handler = _handlers[i];
                if (typeof (handler) === "function") {
                    try {
                        result = handler.apply(null, arguments);
                        if (typeof (result) !== "undefined") {
                            results.push(result);
                        }
                    } catch (cbex) {
                        PeopleMedia.CP.handleException(cbex, { oid: Oid, method: "invoke callback" });
                    }
                }
            }
            return results;
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "invoke" });
        }
    };

    var hasMessageSummary = function () {
        return $jq('.errorMessageSummary').length > 0;
    };

    var getMessageSummary = function () {
        return $jq('.errorMessageSummary');
    };

    var hasFieldLevelError = function (fieldName) {
        return $jq('.error_' + fieldName).length > 0;
    };

    var getFieldLevelError = function (fieldName) {
        return $jq('.error_' + fieldName).filter('.fieldError');
    };

    var addOnShowMessages = function (cb) {
        addHandler(cb);
    };

    var removeOnShowMessages = function (cb) {
        removeHandler(cb);
    };

    var reset = function() {
        try {
            if (hasMessageSummary) {
                getMessageSummary().html('');
                getMessageSummary().hide();
                //getMessageSummary().fadeTo('fast', '1.0');
            }

            $jq('.fieldError').each(function() {
                $jq(this).hide();
            });

            if (_messages) {
                for (var i = 0; i < _messages.length; i++) {
                    var field = $jq('input[name="' + _messages[i].Field + '"]')[0];
                    var label = $jq('label[for="' + _messages[i].Field + '"]')[0];

                    $jq(label).removeClass('invalidFieldLabel');
                    $jq(field).removeClass('invalidField');
                }
            }

            _messages = null;
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "reset" });
        }
    };

    var show = function(messages) {
        try {
            invoke(messages);

            reset();

            var messageTracking = [];
            _messages = messages;

            if (hasMessageSummary()) {
                getMessageSummary().html('<ul></ul>');
                getMessageSummary().show();
            }

            for (var i = 0; i < messages.length; i++) {
                if (messages[i].Field) {
                    var field = $jq('input[name="' + messages[i].Field + '"]');
                    var label = $jq('label[for="' + messages[i].Field + '"]');

                    label.addClass('invalidFieldLabel');
                    field.addClass('invalidField');
                    if (i === 0 && field.length > 0) {
                        field.focus();
                    }
                    if (hasFieldLevelError(messages[i].Field)) {
                        var fieldError = getFieldLevelError(messages[i].Field);
                        fieldError.html(messages[i].Text);
                        fieldError.show();
                    }
                }

                if (hasMessageSummary()) {
                    $jq('UL', getMessageSummary()).append('<li>' + messages[i].Text + '</li>');
                }
                if (messages[i].Text && ((messages[i].Field && hasFieldLevelError(messages[i].Field)) || hasMessageSummary())) {
                    messageTracking.push(messages[i].Text);
                }
            }

            if (messageTracking.length > 0) {
                PeopleMedia.CP.trackRegistrationEventShowRegErrors(messageTracking);
            }
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "show" });
        }
    };

    var showEmailError = function(messages) {
        try {
            var messageTracking = [];
            reset();

            _messages = messages;

            if (hasMessageSummary()) {
                getMessageSummary().html('<ul></ul>');
                getMessageSummary().show();
            }

            for (var i = 0; i < messages.length; i++) {
                if (messages[i].Field) {
                    var field = $jq('input[name="' + messages[i].Field + '"]');
                    var label = $jq('label[for="' + messages[i].Field + '"]');

                    label.addClass('invalidFieldLabel');
                    field.addClass('invalidField');
                    if (i === 0 && field.length > 0) {
                        field[0].focus();
                    }
                }

                if (hasMessageSummary()) {
                    $jq('UL', getMessageSummary()[0]).append('<li><p class="emailerror">' +
                        messages[i].Text +
                        '</p><p class="emailyes">Yes</p><input id="correctEmailAddressChkBox" name="" type="checkbox" value="" class="emailsavecheck"></li>');
                }
                if (messages[i].Text && hasMessageSummary()) {
                    messageTracking.push(messages[i].Text);
                }
            }
            if (messageTracking.length > 0) {
                PeopleMedia.CP.trackRegistrationEventShowRegErrors(messageTracking);
            }
        } catch (ex) {
            PeopleMedia.Diagnostics.handleException(ex, { oid: Oid, method: "showEmailError" });
        }
    };

    var dim = function() {
        try {
            if (hasMessageSummary) {
                getMessageSummary().fadeTo('slow', '0.3');
            }
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "dim" });
        }
    };

    return {
        addOnShowMessages: addOnShowMessages,
        removeOnShowMessages: removeOnShowMessages,
        reset: reset,
        show: show,
        showEmailError: showEmailError,
        dim: dim
    };
})(jQuery);

PeopleMedia.CP.Utilities = (function () {
    "use strict";
    var Oid = "PeopleMedia.CP.Utilities";

    var dateStringToDate = function(dtString) {
        /// <summary>Converts a JSON ISO or MSAJAX string into a date object</summary>    
        /// <param name="dtString" type="String">Date string to convert</param>
        /// <returns type="Date" mayBeNull="true">Javascript Date or null if invalid date</returns>
        try {
            var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;
            var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
            var a = reISO.exec(dtString);
            if (a) {
                return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]));
            }
            a = reMsAjax.exec(dtString);
            if (a) {
                var b = a[1].split("-");
                if (a[1].charAt(0) === '-') {
                    return new Date(-b[1]);
                } else {
                    return new Date(+b[0]);
                }
            }
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "dateStringToDate" });
        }
        return null;
    };

    var parseDateString = function (dtString) {
        var reYYYYMMDD = /^(\d{4})(?:-|\/)(\d{1,2})(?:-|\/)(\d{1,2})$/;
        var reMMDDYYYY = /^(\d{1,2})(?:-|\/)(\d{1,2})(?:-|\/)(\d{4})$/;
        var result = { dtMonth: null, dtDay: null, dtYear: null, dt: null, isValid: false, formattedYYYYMMDD: null };
        try {
            if (dtString) {
                var s = reYYYYMMDD.exec(dtString);
                if (s) {
                    result.dtYear = +s[1];
                    result.dtMonth = +s[2];
                    result.dtDay = +s[3];
                } else {
                    s = reMMDDYYYY.exec(dtString);
                    if (s) {
                        result.dtMonth = +s[1];
                        result.dtDay = +s[2];
                        result.dtYear = +s[3];
                    }
                }
                if (result.dtYear !== null || result.dtMonth !== null || result.dtDay !== null) {
                    result.dt = new Date(result.dtYear, result.dtMonth - 1, result.dtDay);
                    result.isValid = ((result.dt.getFullYear() === result.dtYear) &&
                        (result.dtMonth === result.dt.getMonth() + 1) &&
                        (result.dtDay === result.dt.getDate()));
                    var month = result.dtMonth.toString();
                    month = (month.length > 1) ? month : "0" + month;
                    var day = result.dtDay.toString();
                    day = (day.length > 1) ? day : "0" + day;
                    result.formattedYYYYMMDD = result.dtYear.toString() + "-" + month + "-" + day;
                }
            }
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "parseDateString" });
        }
        return result;
    };

    var formatDateMMDDYYYY = function(date) {
        /// <summary>Return age in years between start date and end date</summary>
        /// <param name="date" type="Object">Date object</param>
        /// <returns type="string">Date formatted as MM/DD/YYYY</returns>
        try {
            if (date && date.getMonth && date.getDate && date.getFullYear) {
                return ((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
            }
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "formatDateMMDDYYYY" });
        }
        return "";
    };

    var formatDateYYYYMMDD = function(date) {
        /// <summary>Return age in years between start date and end date</summary>
        /// <param name="date" type="Object">Date object</param>
        /// <returns type="string">Date formatted as MM/DD/YYYY</returns>
        try {
            if (date && date.getMonth && date.getDate && date.getFullYear) {
                var year = date.getFullYear().toString();
                var month = (date.getMonth() + 1).toString();
                month = (month.length > 1) ? month : '0' + month;
                var day = date.getDate().toString();
                day = (day.length > 1) ? day : '0' + day;
                return year + '-' + month + '-' + day;
            }
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "formatDateYYYYMMDD" });
        }
        return "";
    };

    var formatDateYYYYMMDD_HHMMSS = function(date) {
        // return Date formatted as YYYYMMDD_HHMMSS
        try {
            if (date && date.getMonth && date.getDate && date.getFullYear) {
                var year = date.getFullYear().toString();
                var month = (date.getMonth() + 1).toString();
                month = (month.length > 1) ? month : '0' + month;
                var day = date.getDate().toString();
                day = (day.length > 1) ? day : '0' + day;
                var hour = (date.getHours()).toString();
                hour = (hour.length > 1) ? hour : '0' + hour;
                var minute = (date.getMinutes()).toString();
                minute = (minute.length > 1) ? minute : '0' + minute;
                var sec = (date.getSeconds()).toString();
                sec = (sec.length > 1) ? sec : '0' + sec;
                return year + month + day + "_" + hour + minute + sec;
            }
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "formatDateYYYYMMDD_HHMMSS" });
        }
        return "";
    };

    var calculateAgeInYears = function (startDate, endDate) {
        /// <summary>Return age in years between start date and end date</summary>
        /// <param name="startDate" type="Object">Starting Date object (e.g. birthdate)</param>
        /// <param name="endDate" type="Object" optional="true">Ending Date object (uses current date if not specified)</param>
        /// <returns type="Number" integer="true" mayBeNull="true">Age in years or null if there is an exception</returns>
        try {
            if (arguments.length === 0 || typeof (startDate) === 'undefined' || startDate === null || !startDate.getMonth || !startDate.getDate || !startDate.getFullYear) {
                return null;
            }
            // use current date for endDate if it is missing or not a Date object
            var end = (arguments.length > 1 && typeof (endDate) !== 'undefined' && endDate !== null && endDate.getMonth && endDate.getDate && endDate.getFullYear) ? endDate : new Date();
            var age = end.getFullYear() - startDate.getFullYear();
            if (end.getMonth() < startDate.getMonth() || (end.getMonth() === startDate.getMonth() && end.getDate() < startDate.getDate())) {
                age--;
            }
            return age;
        }
        catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "calculateAgeInYears" });
            return null;
        }
    };

    var isNullOrWhiteSpace = function(str) {
        try {
            if (typeof (str) === 'undefined' || str === null) {
                return true;
            }
            return !/\S/.test(str);
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "isNullOrWhiteSpace" });
        }
    };

    var isGuid = function(str) {
        try {
            var regex = new RegExp(/^([0-9a-fA-F]){8}(-([0-9a-fA-F]){4}){3}-([0-9a-fA-F]){12}$/);
            return regex.test(str);
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "isGuid" });
        }
    };

    var isNullOrMissing = function (val) {
        return (typeof (val) === 'undefined' || val === null);
    };

    var isNullOrEmpty = function (val) {
        return (typeof (val) === 'undefined' || val === null || val === '');
    };

    var isArray = function(val) {
        try {
            return (Array.isArray) ? Array.isArray(val) : Object.prototype.toString.call(val) === '[object Array]';
        } catch (ex) {
            return false;
        }
    };

    var simpleArrayToString = function (val) {
        var result = "";
        if (typeof (val) === "undefined") {
            result = "(undefined)";
        } else if (typeof (val) === null) {
            result = "(null)";
        } else if (Object.prototype.toString.call(val) === '[object Array]') {
            result = "[" + val.join(",") + "]";
        } else {
            result = val.toString();
        }
        return result;
    };

    var formatHeight = function (heightInInches) {
        var display = "";
        try {
            var height = parseInt(heightInInches, 10);
            var feet = Math.floor(height / 12);
            if (isNaN(feet)) {
                feet = 0;
            }
            var inches = height - (feet * 12);
            if (isNaN(inches)) {
                inches = 0;
            }
            display = feet + "'" + inches + "\"";
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "getformattedHeight" });
        }
        return display;
    };

    var transitionEndEventName = function() {
        var i;
        var el = document.createElement('div');
        var transitions = {
            'transition': 'transitionend',
            'OTransition': 'otransitionend',  // oTransitionEnd in very old Opera
            'MozTransition': 'transitionend',
            'WebkitTransition': 'webkitTransitionEnd'
        };

        for (i in transitions) {
            if (transitions.hasOwnProperty(i) && typeof(el.style[i]) !== "undefined") {
                return transitions[i];
            }
        }
        return null;
    };

    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    var debounce = function (func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    };

    // used by enable/disable scrolling functions to maintain y position on page
    var scrollPosition = 0;

    var enableBodyScrolling = function () {
        $jq("body").removeClass("no-scroll").css("top", "auto");
        window.scrollTo(0, scrollPosition);
    };

    var disableBodyScrolling = function () {
        scrollPosition = window.pageYOffset;
        $jq("body").addClass("no-scroll").css("top", "-" + scrollPosition + "px");
    };

    var isCommandKey = function (key) {
        // movement or special keys
        // backspace, caps lock, shift, ctrl, alt, end, home, left, right, up, down, pgup, pgdn, ins, del
        return (key === 8 || key === 20 || (key >= 16 && key <= 18) || (key >= 33 && key <= 40) || key === 45 || key === 46);
    };

    var formattedTime = function (date) {
        var d = (Object.prototype.toString.call(date) === "[object Date]") ? date : new Date();
        var h = d.getHours();
        var m = d.getMinutes();
        var l = "AM";
        if (h > 12) {
            h = h - 12;
            l = "PM";
        }
        if (m < 10) {
            m = "0" + m;
        }
        return h + ":" + m + " " + l;
    };

    var detectIOS = function () {
        // IE11 or Android
        if (window.MSStream || navigator.userAgent.match(/Android/i)) {
            return false;
        }
        // include Macintosh for iPad after iOS 13
        return (navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/Macintosh/i)
        );
    };

    return {
        dateStringToDate: dateStringToDate,
        parseDateString: parseDateString,
        formatDateMMDDYYYY: formatDateMMDDYYYY,
        formatDateYYYYMMDD: formatDateYYYYMMDD,
        formatDateYYYYMMDD_HHMMSS: formatDateYYYYMMDD_HHMMSS,
        calculateAgeInYears: calculateAgeInYears,
        isNullOrWhiteSpace: isNullOrWhiteSpace,
        isGuid: isGuid,
        isNullOrMissing: isNullOrMissing,
        isNullOrEmpty: isNullOrEmpty,
        isArray: isArray,
        simpleArrayToString: simpleArrayToString,
        formatHeight: formatHeight,
        transitionEndEventName: transitionEndEventName,
        debounce: debounce,
        enableBodyScrolling: enableBodyScrolling,
        disableBodyScrolling: disableBodyScrolling,
        isCommandKey: isCommandKey,
        formattedTime: formattedTime,
        detectIOS: detectIOS
    };
})();

PeopleMedia.CP.Consent = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.CP.Consent";

    var _consentAccepted = false;
    var _personalizedConsent = false;
    var _rejectAllClicked = false;
    var _acceptAllClicked = false;
    var _acceptClicked = false;
    var _personalizeConfirmChoicesClicked = false;
    var _loggedModal = false;

    var $sdk = $jq();
    var sdkInterval = null;
    var sdkIntervalMs = 250;
    var sdkIntervalCount = 0;
    var sdkIntervalMax = 20;        // try for 5 seconds after DOM ready then give up if still not found

    var refreshAfterConsent = function () {
        if (typeof (PeopleMedia.Configuration) !== "undefined" && PeopleMedia.Configuration !== null) {
            return PeopleMedia.Configuration.refreshAfterConsent;
        }
        return false;
    };

    var getConsentAccepted = function () {
        if (_consentAccepted) {
            return true;
        }
        var optanonAlertBoxClosedCookie = PeopleMedia.Net.readCookie("OptanonAlertBoxClosed");
        if (optanonAlertBoxClosedCookie) {
            return true;
        }
        return false;
    };

    var parseConsentGroups = function (str) {
        var map = [null, "StrictlyNecessary", "Performance", "Functional", "Advertising"];
        var result = {};
        var split = (str) ? str.split(",") : [];
        for (var i = 0; i < split.length; i++) {
            var kvp = (split[i]) ? split[i].split(":") : [];
            if (kvp.length === 2 && kvp[0] && (kvp[1] === "1" || kvp[1] === "0")) {
                var keyIndex = parseInt(kvp[0], 10);
                if (!isNaN(keyIndex) && keyIndex > 0 && keyIndex < map.length) {
                    var key = map[keyIndex];
                    if (key) {
                        result[key] = (kvp[1] === "1");
                    }
                }
            }
        }
        return result;
    };

    var getConsentGroups = function () {
        var optanonConsentCookie = PeopleMedia.Net.readCookie("OptanonConsent");
        var consent = (optanonConsentCookie) ? PeopleMedia.Net.parseKeyValueString(optanonConsentCookie) : null;
        var groups = (consent && consent.groups) ? consent.groups : "";
        return parseConsentGroups(groups);
    };

    var getPageId = function () {
        return (typeof (PeopleMedia.Configuration) !== "undefined" && typeof (PeopleMedia.Configuration.pageDefinitionId) !== "undefined") ? PeopleMedia.Configuration.pageDefinitionId : null;
    };

    var isEventValid = function (value) {
        return typeof (value) === "object" && value.eventName;
    };

    var trackConsentEvents = function (params) {
        try {
            var validEvents = [];
            if (params && params.events && Array.isArray(params.events)) {
                Array.prototype.push.apply(validEvents, params.events.filter(isEventValid));
            }
            if (validEvents.length === 0 || !Array.isArray(validEvents)) {
                // do nothing if no events
                trackConsentEventsComplete(null, { status: "Success" }, params);
                return;
            }

            var pageDefinitionId = getPageId();
            var url = "/v3/ajax/TrackConsentEvents";
            var startTime = new Date().getTime();
            var timeoutValue = 3000;

            var ajaxData = {
                "Events": validEvents,
                "PageDefinitionId": pageDefinitionId
            };
            $jq.ajax({
                headers: PeopleMedia.CP.LpsPostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    trackConsentEventsComplete(null, response, params);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    PeopleMedia.CP.logAjax({ oid: Oid, method: "trackConsentEvents", url: url, duration: duration, jqXHR: jqXHR, textStatus: textStatus, errorThrown: errorThrown });
                    var errorObj = PeopleMedia.CP.convertAjaxErrorToFault(jqXHR, textStatus, errorThrown);
                    trackConsentEventsComplete(errorObj, null, params);
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "trackConsentEvents" });
            trackConsentEventsComplete(PeopleMedia.CP.createErrorObject({ ex: ex }), null, params);
        }
    };

    var trackConsentEventsComplete = function (err, response, params) {
        if (params && typeof (params.onComplete) === "function") {
            params.onComplete(err, response, params);
        }
    };

    // Track single event with callback
    var trackConsentEvent = function (eventName, eventValue, callback) {
        // make tracking call asynchronously
        setTimeout(function () {
            trackConsentEvents({
                events: [
                    {
                        eventName: eventName,
                        eventValue: eventValue
                    }
                ],
                onComplete: callback
            });
        }, 0);
    };

    var trackConsentChanged = function (params) {
        try {
            var trackedEvents = [];
            _consentAccepted = true;
            var pageId = getPageId();
            var onComplete = (params && params.onComplete) ? params.onComplete : null;
            var alertBoxClosed = (params && params.data && params.data.alertBoxClosed) ? params.data.alertBoxClosed : null;
            var consent = (params && params.data && params.data.consent) ? PeopleMedia.Net.parseKeyValueString(params.data.consent) : null;
            var groupString = (consent && consent.groups) ? consent.groups : null;
            var groupParsed = parseConsentGroups(groupString);
            var eventValue = {
                alertBoxClosed: alertBoxClosed,
                groups: groupParsed,
                shouldRefresh: false
            };
            if (groupString) {
                eventValue.groupString = groupString;
            }
            if (_acceptClicked) {
                eventValue.accept = true;
            }
            if (_personalizedConsent) {
                eventValue.personalized = true;
            }
            if (_rejectAllClicked) {
                eventValue.rejectAll = true;
            }
            if (_acceptAllClicked) {
                eventValue.acceptAll = true;
            }
            if (_personalizeConfirmChoicesClicked) {
                eventValue.personalizedConfirmChoices = true;
            }
            if (pageId) {
                eventValue.pageId = pageId;
            }
            if (refreshAfterConsent() && typeof (eventValue.groups) === "object" && eventValue.groups.Advertising) {
                eventValue.shouldRefresh = true;
            }
            trackedEvents.push({
                eventName: "ConsentChanged",
                eventValue: JSON.stringify(eventValue)
            });
            trackConsentEvents({ events: trackedEvents, groups: groupParsed, shouldRefresh: eventValue.shouldRefresh, onComplete: onComplete });
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "trackConsentChanged" });
        }
    };

    var onConsentChanged = PeopleMedia.CP.Utilities.debounce(function (e) {
        var data = (e && e.detail) ? e.detail : null;
        trackConsentChanged({
            data: data,
            onComplete: function (err, response, params) {
                // force page refresh for server side pixels
                if (!err && params && params.shouldRefresh) {
                    window.location.reload();
                }
            }
        });
    }, 1000, true);

    var modalVisible = function () {
        return ($sdk.length > 0 &&
            $sdk.css("display") !== "none" &&
            $sdk.css("visibility") !== "hidden" &&
            $sdk.css("opacity") !== "0");
    };

    var detectBannerDisplay = function () {
        // We don't get a notification when the banner is shown
        try {
            sdkInterval = setInterval(function () {
                sdkIntervalCount++;
                $sdk = $jq("#onetrust-consent-sdk");
                if ($sdk.length > 0 || sdkIntervalCount >= sdkIntervalMax || getConsentAccepted()) {
                    clearInterval(sdkInterval);
                    sdkInterval = null;
                    if (!_loggedModal && !getConsentAccepted() && $sdk.length > 0 && modalVisible($sdk)) {
                        _loggedModal = true;
                        trackConsentEvent("ConsentShow", "");
                    }
                }
            }, sdkIntervalMs);
        } catch (ex) {
            console.log(ex);
            clearInterval(sdkInterval);
            sdkInterval = null;
        }
    };

    var onConsentPersonalizeTapped = PeopleMedia.CP.Utilities.debounce(function (e) {
        _personalizedConsent = true;
        trackConsentEvent("ConsentPersonalizeTapped", "");
    }, 1000, true);

    var onConsentMainAcceptTapped = PeopleMedia.CP.Utilities.debounce(function (e) {
        _acceptClicked = true;
        trackConsentEvent("ConsentMainAcceptTapped", "");
    }, 1000, true);

    var onConsentMainAllowAllTapped = PeopleMedia.CP.Utilities.debounce(function (e) {
        _acceptAllClicked = true;
        trackConsentEvent("ConsentMainAllowAllTapped", "");
    }, 1000, true);

    var onConsentMainRefuseAllTapped = PeopleMedia.CP.Utilities.debounce(function (e) {
        _rejectAllClicked = true;
        trackConsentEvent("ConsentMainRefuseAllTapped", "");
    }, 1000, true);

    var onConsentPersonalizeConfirmChoicesTapped = PeopleMedia.CP.Utilities.debounce(function (e) {
        _personalizeConfirmChoicesClicked = true;
        trackConsentEvent("ConsentPersonalizeConfirmChoicesTapped", "");
    }, 1000, true);

    var initialize = function () {
        // triggered from script executed in OptanonWrapper
        document.addEventListener("ConsentChanged", onConsentChanged);

        // bind as delegated events outside ready handler
        // "Personalize my choices" button
        $jq(document).on("mousedown", "#onetrust-pc-btn-handler", onConsentPersonalizeTapped);

        // "Accept" button
        $jq(document).on("mousedown", "#onetrust-accept-btn-handler", onConsentMainAcceptTapped);

        // "Allow All" button
        $jq(document).on("mousedown", "#accept-recommended-btn-handler", onConsentMainAllowAllTapped);

        // "Reject All" button
        $jq(document).on("mousedown", ".ot-pc-refuse-all-handler", onConsentMainRefuseAllTapped);

        // "Confirm My Choices" button
        $jq(document).on("mousedown", ".save-preference-btn-handler", onConsentPersonalizeConfirmChoicesTapped);

        $jq(function () {
            detectBannerDisplay();
        });
    };

    return {
        get consentAccepted() { return getConsentAccepted(); },
        get consentGroups() { return getConsentGroups(); },
        initialize: initialize
    };
})(jQuery);

PeopleMedia.CP.ArkoseCaptcha = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.CP.ArkoseCaptcha";
    var _arkoseTimeout = 15000;
    var _captchaToken = null;
    var _captchaLocation = null;
    var _pageDefinitionId = null;
    var _onValidateComplete = null;
    var _$form = $jq();
    var _selector = null;

    var _isScriptLoaded = false;
    var _scriptsReady = false;
    var _scriptsFailed = false;
    var _enforceTimer = null;
    var _arkose = null;

    var updateCaptchaTokenInForm = function () {
        if (_$form && _$form.length > 0 && _captchaToken) {
            var $inputToken = _$form.find("input[name='CaptchaToken']");
            if ($inputToken.length > 0) {
                $inputToken.val(_captchaToken);
            }
        }
    };

    var includeCaptchaFieldsInForm = function (params) {
        var captchaToken = params && params.captchaToken ? params.captchaToken : "";
        var captchaLocation = params && params.captchaLocation ? params.captchaLocation : "";
        var pageDefinitionId = params && params.pageDefinitionId ? params.pageDefinitionId : (typeof (PeopleMedia.PageDefinitionId) !== "undefined" ? PeopleMedia.PageDefinitionId : null);
        var $form = params && params.$form ? params.$form : $jq();
        if ($form && $form.length > 0) {
            var $inputToken = $form.find("input[name='CaptchaToken']");
            var $inputAction = $form.find("input[name='CaptchaLocation']");
            var $inputPageDefinitionId = $form.find("input[name='PageDefinitionId']");

            if ($inputToken.length === 0) {
                $form.append("<input name='CaptchaToken' type='hidden' value='" + captchaToken + "'>");
            } else {
                $inputToken.val(captchaToken);
            }

            if ($inputAction.length === 0) {
                $form.append("<input name='CaptchaLocation' type='hidden' value='" + captchaLocation + "'>");
            } else {
                $inputAction.val(captchaLocation);
            }

            if ($inputPageDefinitionId.length === 0) {
                $form.append("<input name='PageDefinitionId' type='hidden' value='" + pageDefinitionId + "'>");
            } else {
                $inputPageDefinitionId.val(pageDefinitionId);
            }
        }
    };

    var onArkoseEnforcementComplete = function (response) {
        var token = (response && response.token) ? response.token.toString() : "";
        _captchaToken = token;
        updateCaptchaTokenInForm();
        if (typeof (_onValidateComplete) === "function") {
            _onValidateComplete(null, response);
        }
    };

    var timerExpired = function () {
        clearTimeout(_enforceTimer);
        if (!_isScriptLoaded) {
            _scriptsReady = false;
            _isScriptLoaded = false;
            _scriptsFailed = true;
        }
    };

    var setArkose = function (enforcement) {
        _arkose = enforcement;
    };

    var setupEnforcement = function (enforcement) {
        enforcement.setConfig({
            onReady: function () {
                _scriptsReady = true;
            },
        });
        setArkose(enforcement);
        _isScriptLoaded = true;
    };

    var loadScripts = function () {
        try {
            _scriptsReady = false;
            var arkoseScript = document.createElement("script");
            arkoseScript.id = "arkosescript";
            arkoseScript.type = "text/javascript";
            var attrib = document.createAttribute("data-callback");
            attrib.value = "setupEnforcement";
            arkoseScript.setAttributeNode(attrib);
            arkoseScript.src = "https://client-api.arkoselabs.com/v2/" + PeopleMedia.Configuration.arkoseSiteKey + "/api.js";
            document.head.appendChild(arkoseScript);
        }
        catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "loadArkose" });
            _scriptsReady = false;
            _isScriptLoaded = false;
            _scriptsFailed = true;
        }
    };

    var execute = function () {
        if (!!_arkose && _scriptsReady) {
            _arkose.setConfig({
                selector: _selector,
                onCompleted: onArkoseEnforcementComplete,
                onReady: function () {
                    _scriptsReady = true;
                },
            });
            _arkose.run();
        }
    };

    var initialize = function (options) {
        _captchaToken = null;
        _captchaLocation = null;
        _pageDefinitionId = null;
        _onValidateComplete = null;
        _$form = $jq();
        _selector = null;
        if (options) {
            if (options.captchaLocation) {
                _captchaLocation = options.captchaLocation;
            }
            if (options.pageDefinitionId) {
                _pageDefinitionId = options.pageDefinitionId;
            }
            if (options.$form && options.$form.length > 0) {
                _$form = options.$form;
            }
            if (options.selector) {
                _selector = options.selector;
            }
            if (typeof (options.onValidateComplete) === "function") {
                _onValidateComplete = options.onValidateComplete;
            }
            if (options.includeCaptchaFieldsInForm === true && _$form && _$form.length > 0) {
                includeCaptchaFieldsInForm({
                    captchaToken: _captchaToken,
                    captchaLocation: _captchaLocation,
                    $form: _$form,
                    pageDefinitionId: _pageDefinitionId
                });
            }
        }
        window.setupEnforcement = setupEnforcement;
        loadScripts();
        _enforceTimer = window.setTimeout(timerExpired, _arkoseTimeout);
    };

    return {
        get scriptsFailed() { return _scriptsFailed; },
        get scriptsReady() { return _scriptsReady; },
        get selector() { return _selector; },
        initialize: initialize,
        includeCaptchaFieldsInForm: includeCaptchaFieldsInForm,
        updateCaptchaTokenInForm: updateCaptchaTokenInForm,
        onArkoseEnforcementComplete: onArkoseEnforcementComplete,
        execute: execute
    };
})(jQuery);

// has dependencies on jQuery and lottie
PeopleMedia.CP.Animations = (function ($jq) {
    var Oid = "PeopleMedia.CP.Animations";
    var animations = {};
    var _loaderTimeout = null;
    var _loaderDelay = 3000;

    var setupLottieAnimations = function () {
        try {
            // preload the animation files
            $jq.ajax({
                'url': PeopleMedia.Configuration.themeAnimationPath + "/Loader.json",
                'dataType': "json",
                'success': function (data) {
                    document.querySelectorAll('.loader2 .spinner .lottie-container').forEach(
                        function (item) {
                            var animation = lottie.loadAnimation({
                                container: item,
                                animationData: data,
                                renderer: 'svg',
                                loop: true,
                                autoplay: false,
                                name: "Loader"
                            });
                            animations["loader"] = animation;
                            $jq(".loader2").addClass("lottie");
                            $jq(".loader2 .spinner").removeClass("default-image");
                        });
                }
            });
            $jq(window).trigger("lottieload");
        }
        catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "setupLottieAnimations" });
        }
    };

    var loadLottie = function () {
        try {
            if (typeof (lottie) === "undefined") {
                var lottieScript = document.createElement("script");
                lottieScript.id = "lottiescript";
                lottieScript.type = "text/javascript";
                lottieScript.onload = setupLottieAnimations;
                lottieScript.src = PeopleMedia.Configuration.imagePath + "/scripts/lottie_5.7.12.min.js";
                document.head.appendChild(lottieScript);
            } else {
                setupLottieAnimations();
            }
        }
        catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "loadLottie" });
        }
    };

    var showLoader = function (options) {
        var $loader = $jq(".loader2");
        var useLegacy = options && options.legacy === true;
        if (useLegacy) {
            // use animated gif instead of lottie
            $loader.find(".lottie-container").addClass("hidden");
            $loader.removeClass("hidden").find(".spinner").addClass("default-image");
            return;
        }
        var anim = animations["loader"];
        $loader.removeClass("hidden");
        if (typeof (lottie) === "object" && typeof (anim) === "object") {
            anim.play();
        }
    };

    var hideLoader = function (options) {
        var $loader = $jq(".loader2");
        var useLegacy = options && options.legacy === true;
        if (useLegacy) {
            $loader.addClass("hidden");
            if ($loader.hasClass("lottie")) {
                // reset back to default behavior
                $loader.find(".lottie-container").removeClass("hidden");
                $loader.find(".spinner").removeClass("default-image");
            }
            return;
        }
        var anim = animations["loader"];
        if (typeof (lottie) === "object" && typeof (anim) === "object") {
            anim.stop();
        }
        $loader.addClass("hidden");
    };

    var loaderVisible = function () {
        var $loader = $jq(".loader2");
        return $loader.length > 0 && !($loader.hasClass("hidden"));
    };

    var cancelDelayedLoader = function (options) {
        if (_loaderTimeout) {
            // console.log("Cancelling delayed spinner " + spinnerTimeout + " at " + moment().format("YYYY-MM-DD HH:mm:ss.SSS"));
            clearTimeout(_loaderTimeout);
        }
        _loaderTimeout = null;
        if (PeopleMedia.Animations.loaderVisible()) {
            hideLoader(options);
        }
    };

    var showDelayedLoader = function (options) {
        var delayMilliseconds = (options && typeof (options.delayMilliseconds) === "number" && options.delayMilliseconds >= 0) ? options.delayMilliseconds : _loaderDelay;
        if (_loaderTimeout) {
            // console.log("Duplicate delayed spinner " + _loaderTimeout + " detected at " + new Date());
            clearTimeout(_loaderTimeout);
        }
        // wait before showing spinner
        _loaderTimeout = setTimeout(function () {
            if (_loaderTimeout) {
                // console.log("Showing delayed spinner " + _loaderTimeout + " at " + new Date());
                clearTimeout(_loaderTimeout);
                _loaderTimeout = null;
                showLoader(options);
            }
        }, delayMilliseconds);
        // console.log("Starting delayed spinner " + _loaderTimeout + " at " + new Date());
    };

    var init = function () {
        loadLottie();
    };

    return {
        init: init,
        showLoader: showLoader,
        hideLoader: hideLoader,
        loaderVisible: loaderVisible,
        showDelayedLoader: showDelayedLoader,
        cancelDelayedLoader: cancelDelayedLoader
    };
})(jQuery);

// load these before $jq(document).ready
(function () {
    "use strict";
    try {
        PeopleMedia.CP.initLogging();
        PeopleMedia.CP.init(PeopleMedia.Configuration);
        PeopleMedia.CP.Consent.initialize();
    }
    catch (ex) {
        PeopleMedia.CP.handleException(ex, { oid: "lps_main module", method: "CP init" });
    }
})();

$jq(function () {
    "use strict";
    try {
        PeopleMedia.CP.Animations.init();
        PeopleMedia.CP.initDefaultForm();
        PeopleMedia.CP.ready();
    }
    catch (ex) {
        PeopleMedia.CP.handleException(ex, { oid: "lps_main document ready", method: "CP setup" });
    }
});

